import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import "./photoGrid.scss";

const PhotoGrid = ({ anniversaryCount, photos }) => {
  let anniversaryCountSuffix;
  let lastCharInAnniversaryCount = (anniversaryCount + "").slice(-1);
  switch (lastCharInAnniversaryCount) {
    case "1":
      anniversaryCountSuffix = "st";
      break;
    case "2":
      anniversaryCountSuffix = "nd";
      break;
    case "3":
      anniversaryCountSuffix = "rd";
      break;
    default:
      anniversaryCountSuffix = "th";
      break;
  }

  return (
    <div className="photo-grid-wrapper">
      <div className="photo-grid-section">
        <div className="photo-grid">
          <GatsbyImage
            image={getImage(photos[0])}
            className="photo1"
            alt="photo1"
          />
          <GatsbyImage
            image={getImage(photos[1])}
            className="photo2"
            alt="photo2"
          />
          <GatsbyImage
            image={getImage(photos[2])}
            className="photo3"
            alt="photo3"
          />
        </div>
        <div className="blur">
          {anniversaryCount}
          <sub>{anniversaryCountSuffix}</sub>
        </div>
      </div>
    </div>
  );
};

export default PhotoGrid;
